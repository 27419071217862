import { Gondor, Response } from "../Gondor";

export type Types = "id" | "boolean" | "date" | "number" | "string";

export interface Column {
  id: number;
  name: string;
  code: string;
  dataType: Types;
  previousAnswer: boolean;
  catalog: boolean;
  createdAt: Date;
  updatedAt: Date;
}

const ACTIONS = {
  COLUMN: "/column/",
  COLUMN_ITEM: "/column/{id}/",
};

export function find(params?: object) {
  return Gondor.API.get<Response<Column[]>>(ACTIONS.COLUMN, { params });
}

export function findOne(id: number | string) {
  id = id.toString();
  return Gondor.API.get(ACTIONS.COLUMN_ITEM.replace("{id}", id));
}

export function create(body: object) {
  return Gondor.API.post(ACTIONS.COLUMN, body);
}

export function update(id: number | string, body: object) {
  id = id.toString();
  return Gondor.API.patch(ACTIONS.COLUMN_ITEM.replace("{id}", id), body);
}

export function destroy(id: number | string) {
  id = id.toString();
  return Gondor.API.delete(ACTIONS.COLUMN_ITEM.replace("{id}", id));
}
